import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {mediaItemClassification, mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../media-entitlement/withMediaActions';
import './CTAButtonBarPopoverView.scss';
import CTAButton from '../ui-elements/cta-button/CTAButton';

@withMediaActions
class CTAButtonBarPopoverView extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
        hidePopover: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {isUserSignedIn, userAccount} = this.props;
        const {mediaItem, currentEntitlementsByMediaItem, providers} = this.props;
        const {isFastCheckoutInProgress, fastCheckoutMediaItemOffer, checkoutSubscription} = this.props;
        const {startMediaItemOfferPlayback, openMediaItemRoute, hidePopover} = this.props;
        const hasTVODOffers = !!mediaItem.offers.find(offer => offer.offerType === mediaItemOfferType.TVOD);

        // display episodic CTA buttons for tv-shows and seasons
        if (mediaItem.classification === mediaItemClassification.TV_SHOW
            || mediaItem.classification === mediaItemClassification.SEASON) {
            const goToMediaItem = () => {
                hidePopover();
                openMediaItemRoute(mediaItem);
            };

            return isUserSignedIn ? (
                <div className="vub-c-cta-button-bar-popover-view">
                    <CTAButton
                        onClick={goToMediaItem}
                        className="vub-c-cta-button--secondary"
                    >
                        Season Details
                    </CTAButton>
                    <div className="vub-c-cta-button-bar-popover-view__play-message vub-c-cta-button-bar-popover-view__play-message--secondary">
                        Find available episodes
                    </div>
                </div>
            ) : null;
        }

        // display episodic CTA buttons for tv-shows and seasons
        if (mediaItem.classification === mediaItemClassification.TV_SHOW
            || mediaItem.classification === mediaItemClassification.SEASON) {
            const goToMediaItem = () => {
                hidePopover();
                openMediaItemRoute(mediaItem);
            };

            return isUserSignedIn ? (
                <div className="vub-c-cta-button-bar-popover-view">
                    <CTAButton
                        onClick={goToMediaItem}
                        className="vub-c-cta-button--secondary"
                    >
                        Season Details
                    </CTAButton>
                    <div className="vub-c-cta-button-bar-popover-view__play-message vub-c-cta-button-bar-popover-view__play-message--secondary">
                        Find available episodes
                    </div>
                </div>
            ) : null;
        }

        // display SVOD CTA buttons for mediaItem that has no TVOD offers
        if (!hasTVODOffers) {
            return (
                <div className="vub-c-cta-button-bar-popover-view">
                    {mediaItem.offers
                        .filter(offer => offer.offerType === mediaItemOfferType.SVOD)
                        .map(offer => {
                            const fastCheckoutSubscription = () => {
                                checkoutSubscription(offer);
                                hidePopover();
                            };
                            const provider = providers.get(offer.provider);
                            const isUserSubscribed = isUserSignedIn && !!userAccount.subscriptions
                                .find(subscription => subscription.provider === offer.provider);
                            const startOfferPlayback = () => {
                                startMediaItemOfferPlayback(offer);
                                hidePopover();
                            };

                            return !isUserSubscribed ? (
                                <Fragment key={offer.id}>
                                    <CTAButton
                                        onClick={fastCheckoutSubscription}
                                        isDisabled={isFastCheckoutInProgress}
                                    >
                                        <span>
                                            Subscribe
                                        </span>
                                    </CTAButton>
                                    {provider && (
                                        <img
                                            className="vub-c-cta-button-bar-popover-view__service-logo"
                                            src={provider.serviceLogo}
                                            alt="service-icon"
                                            width="32"
                                        />
                                    )}
                                </Fragment>
                            ) : (
                                <Fragment key={offer.id}>
                                    <CTAButton onClick={startOfferPlayback}>
                                        <span>Play</span>
                                    </CTAButton>
                                    {provider && (
                                        <img
                                            className="vub-c-cta-button-bar-popover-view__service-logo"
                                            src={provider.serviceLogo}
                                            alt="service-icon"
                                            width="32"
                                        />
                                    )}
                                    <span className="vub-c-cta-button-bar-popover-view__play-message">
                                        Included with your account
                                    </span>
                                </Fragment>
                            );
                        })}
                </div>
            );
        }

        // display TVOD CTA buttons for media that has at least one offer of TVOD type
        if (hasTVODOffers) {
            const entitlement = currentEntitlementsByMediaItem && currentEntitlementsByMediaItem.get(mediaItem.id);
            const purchasedOffer = entitlement ? mediaItem.offers
                .find(offer => offer.id === entitlement.offerId) : null;
            const startOfferPlayback = () => {
                startMediaItemOfferPlayback(purchasedOffer);
                hidePopover();
            };

            return !entitlement ? (
                <div className="vub-c-cta-button-bar-popover-view">
                    {mediaItem.offers
                        .filter(offer => offer.offerType !== mediaItemOfferType.SVOD)
                        .map(offer => {
                            const fastCheckoutOffer = () => {
                                fastCheckoutMediaItemOffer(offer);
                                hidePopover();
                            };
                            const provider = providers.get(offer.provider);
                            const price = offer.price.toLocaleString('en', {
                                style: 'currency',
                                currency: offer.currency,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            });

                            return (
                                <Fragment key={offer.id}>
                                    <CTAButton
                                        onClick={fastCheckoutOffer}
                                        isDisabled={isFastCheckoutInProgress}
                                    >
                                        <span>
                                            Rent <span className="vub-c-cta-button-atlas__price">{price}</span>
                                        </span>
                                    </CTAButton>
                                    {provider && (
                                        <img
                                            className="vub-c-cta-button-bar-popover-view__service-logo"
                                            src={provider.serviceLogo}
                                            alt="service-icon"
                                            width="32"
                                        />
                                    )}
                                </Fragment>
                            );
                        })}
                </div>
            ) : (
                <div className="vub-c-cta-button-bar-popover-view">
                    <Fragment key={purchasedOffer.id}>
                        <CTAButton onClick={startOfferPlayback}>
                            Play
                        </CTAButton>
                        <div className="vub-c-cta-button-bar-popover-view__play-message">
                            Playable until {entitlement.rentalExpiryTime
                                && entitlement.rentalExpiryTime.format('DD/MM/YYYY [at] HH:mm [(GMT)]')}
                        </div>
                    </Fragment>
                </div>
            );
        }

        // return null if no render condition is matched
        return null;
    }
}

export default CTAButtonBarPopoverView;
