import React from 'react';
import PropTypes from 'prop-types';
import {combineClassNames} from '../../ui-kit/utils';
import './ProviderCornerRibbon.scss';

const ProviderCornerRibbon = ({provider, className}) => {
    if (!provider) return null;

    return (
        <div className={combineClassNames('vub-c-provider-corner-ribbon', className)}>
            <img
                className="vub-c-provider-corner-ribbon__image"
                src={provider.serviceOverlayLogo}
                alt="ribbon"
                height="15"
            />
        </div>
    );
};

ProviderCornerRibbon.propTypes = {
    provider: PropTypes.object,
    className: PropTypes.string,
};

ProviderCornerRibbon.defaultProps = {
    provider: null,
    className: null,
};

export default ProviderCornerRibbon;
