import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import './ProgressBar.scss';

const ProgressBar = ({setCurrentTime, currentTime, duration}) => (
    <div className="vub-c-progress-bar">
        <Slider
            max={duration}
            value={currentTime}
            onChange={setCurrentTime}
        />
    </div>
);

ProgressBar.propTypes = {
    setCurrentTime: PropTypes.func,
    currentTime: PropTypes.number,
    duration: PropTypes.number,
};

ProgressBar.defaultProps = {
    setCurrentTime: null,
    currentTime: 0,
    duration: 0,
};

export default ProgressBar;

