import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {withMediaActions, withMediaActionsPropTypes, withMediaActionsDefaultProps} from '../media-entitlement/withMediaActions';
import './MediaItemPreviewButtonView.scss';

@withRouter
@withMediaActions
class MediaItemPreviewButtonView extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {mediaItem, startMediaItemPreviewPlayback} = this.props;
        const startPreviewPlayback = e => {
            e.stopPropagation();
            startMediaItemPreviewPlayback(mediaItem.preview);
        };

        return mediaItem && mediaItem.preview ? (
            <div
                className="vub-c-media-item-preview-button-view"
                onClick={startPreviewPlayback}
            >
                <span className="vub-c-media-item-preview-button-view__logo" />
                <span className="vub-c-media-item-preview-button-view__descriptor">
                    trailer
                </span>
            </div>
        ) : null;
    }
}

export default MediaItemPreviewButtonView;
