import {createSelector} from 'reselect';
import Immutable from 'immutable';
import * as navigationSelectors from '../navigation/navigationSelectors';
import storefrontRowTypes from '../navigation/storefrontRowTypes';
import * as mediaSelectors from '../media/mediaSelectors';
// import mediaPreviewImageCreative from '../assets/images/mwc_placeholder_creative_white.jpg';
import mediaPreviewImageBlack from '../assets/images/bg_player_placeholder_black.jpg';
import mediaPreviewImageNewYear from '../assets/images/mwc_placeholder_new_year.png';

const getMediaBucketMedia = function getMediaBucketMedia(mediaBucketKey, mediaBuckets, mediaItems) {
    const mediaBucket = mediaBuckets.get(mediaBucketKey);
    let media = Immutable.OrderedMap();
    if (mediaBucket) {
        mediaBucket.mediaIds.forEach(mediaItemId => {
            media = media.set(mediaItemId, mediaItems.get(mediaItemId));
        });
    }

    return media;
};

export const createStorefrontRowsSelector = () => createSelector(
    [
        navigationSelectors.getStorefrontRows,
        mediaSelectors.getMediaBuckets,
        mediaSelectors.getMediaItems,
    ],
    (storefrontRows, mediaBuckets, mediaItems) => {
        let storefrontRowsWithData = Immutable.OrderedMap();
        storefrontRows.forEach(storefrontRow => {
            if (storefrontRow.type === storefrontRowTypes.MEDIA_GENRE) {
                const {id: rowId, value: mediaGenreId} = storefrontRow;
                const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_GENRE['${rowId}']['${mediaGenreId}']`;
                const media = getMediaBucketMedia(mediaBucketKey, mediaBuckets, mediaItems);

                storefrontRowsWithData = storefrontRowsWithData.set(storefrontRow.id, storefrontRow.merge({
                    data: media,
                }));
            }

            if (storefrontRow.type === storefrontRowTypes.MEDIA_COLLECTION) {
                const {id: rowId, value: mediaCollectionId} = storefrontRow;
                const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_COLLECTION['${rowId}']['${mediaCollectionId}']`;
                const media = getMediaBucketMedia(mediaBucketKey, mediaBuckets, mediaItems);

                storefrontRowsWithData = storefrontRowsWithData.set(storefrontRow.id, storefrontRow.merge({
                    data: media,
                }));
            }

            if (storefrontRow.type === storefrontRowTypes.MEDIA_SET) {
                const {id: rowId} = storefrontRow;
                const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_SET['${rowId}']`;
                const media = getMediaBucketMedia(mediaBucketKey, mediaBuckets, mediaItems);

                storefrontRowsWithData = storefrontRowsWithData.set(storefrontRow.id, storefrontRow.merge({
                    data: media,
                }));
            }

            if (storefrontRow.type === storefrontRowTypes.CUSTOM_DATA) {
                storefrontRowsWithData = storefrontRowsWithData.set(storefrontRow.id, storefrontRow.merge({
                    data: [
                        // {
                        //     source: {
                        //         url: `//computerrockmedia-euwe.streaming.media.azure.net
                        //              /f2d13b83-ccd9-40cb-934a-a81a2aa8daf8/CReative1920x760.ism/manifest`,
                        //         type: 'application/vnd.ms-sstr+xml',
                        //     },
                        //     imageBoxCover: mediaPreviewImageCreative,
                        //     playbackStartAt: 1000,
                        // },
                        {
                            source: {
                                url: `//computerrockmedia-euwe.streaming.media.azure.net/32664030-1033-4d6d-8cf1-fde183b71e59/CELL_New_Year_1920x760.ism/manifest`,
                                type: 'application/vnd.ms-sstr+xml',
                            },
                            imageBoxCover: mediaPreviewImageNewYear,
                            playbackStartAt: 1000,
                        },
                        {
                            source: {
                                url: `//computerrockmedia-euwe.streaming.media.azure.net/859ca844-90e7-475a-90da-080941c369cf/Black_Marvel_Comp_1920x760_.ism/manifest`,
                                type: 'application/vnd.ms-sstr+xml',
                            },
                            imageBoxCover: mediaPreviewImageBlack,
                            playbackStartAt: 0,
                        },
                    ],
                }));
            }
        });

        return storefrontRowsWithData;
    },
);
