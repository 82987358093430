import {fork, put, take} from 'redux-saga/effects';
import bowser from 'bowser';
import {mediaItemOfferType} from '@comrock/vub-wls-media-service';
import * as actionTypes from '../mediaEntitlementActionTypes';
import createPurchaseOrderItemSVOD from './createPurchaseOrderItemSVOD';
import fetchMediaItemPlaybackSourceRequest from './fetchMediaItemPlaybackSourceRequest';

/**
 * Start mediaItem playback flow
 */
export const startMediaItemOfferPlaybackFlow = function* startMediaItemOfferPlaybackFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.START_MEDIA_ITEM_OFFER_PLAYBACK);

        const {offer} = payload;
        if (offer.offerType === mediaItemOfferType.SVOD) {
            yield fork(createPurchaseOrderItemSVOD, {
                offerId: offer.id,
            });
        }

        // TODO temporary direct trailer playback for new media indexes
        if (offer.assetId.includes('manifest')) {
            const mediaItemPlaybackSourceDTO = {
                url: offer.assetId,
                type: 'application/vnd.ms-sstr+xml',
            };
            yield put({
                type: actionTypes.STORE_MEDIA_ITEM_PLAYBACK_SOURCE,
                payload: {mediaItemPlaybackSourceDTO, playbackMediaItemId: offer.mediaItemId},
            });

            yield take(actionTypes.STOP_MEDIA_ITEM_PLAYBACK);
            yield put({
                type: actionTypes.STORE_MEDIA_ITEM_PLAYBACK_SOURCE,
                payload: {mediaItemPlaybackSourceDTO: null},
            });
            continue;
        }

        const browser = bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowserName();
        yield fork(fetchMediaItemPlaybackSourceRequest, {
            assetId: offer.assetId,
            assetOfferId: offer.assetOfferId,
            scope: 'resolve:asset',
            // over-ride HD rent offer with SD values
            // http://redmine.computerrock.com/issues/30743
            // resolution: `private:${offer.resolution === '1080p' ? 'HD' : 'SD'}`,
            resolution: 'private:SD',
            platformClass: 'WEB',
            platformSubclass: `private:${browserName === 'Internet Explorer' || browserName === 'Microsoft Edge'
                ? 'edge' : browserName.toLowerCase()}`,
            delivery: `${browserName === 'Safari' ? 'hls' : 'dash'}`,
            region: 'uk',
        });
        const fetchMediaItemPlaybackSourceResult = yield take([
            actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_SUCCESS,
            actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR,
        ]);

        if (fetchMediaItemPlaybackSourceResult.type === actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR) continue;

        const {response: mediaItemPlaybackSourceDTO} = fetchMediaItemPlaybackSourceResult.payload;
        yield put({
            type: actionTypes.STORE_MEDIA_ITEM_PLAYBACK_SOURCE,
            payload: {mediaItemPlaybackSourceDTO, playbackMediaItemId: offer.mediaItemId},
        });

        yield take(actionTypes.STOP_MEDIA_ITEM_PLAYBACK);
        yield put({type: actionTypes.STORE_MEDIA_ITEM_PLAYBACK_SOURCE, payload: {mediaItemPlaybackSourceDTO: null}});
    }
};

export default startMediaItemOfferPlaybackFlow;
