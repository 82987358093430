import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {mediaRouteResolver} from '../services';
import {
    withMediaRouteHelpers,
    withMediaRouteHelpersPropTypes,
    withMediaRouteHelpersDefaultProps,
} from '../media/withMediaRouteHelpers';
import * as selectors from './mediaItemViewSelectors';
import {getMediaItem} from '../media/mediaBucketHelpers';
import ContentRow from '../layout/ContentRow';
import MediaItemHeroImage from '../ui-elements/media-item-hero-image/MediaItemHeroImageForTransparentHeader';
import MediaItemDetailsMovie from '../ui-elements/media-item-details/MediaItemDetailsMovie';
import MediaItemFormatTagHD from '../ui-elements/media-item-format-tag/MediaItemFormatTagHD';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortrait from '../ui-elements/media-gallery-tile/MediaGalleryTilePortrait';
import ProviderCornerRibbon from '../ui-elements/provider-corner-ribbon/ProviderCornerRibbon';
import MediaItemPreviewButtonView from '../media-item-preview-button-view/MediaItemPreviewButtonView';
import CTAButtonBarView from '../media-item-cta-button-bar-view/CTAButtonBarView';

@withRouter
@withMediaRouteHelpers({mediaRouteResolver})
class MediaItemMovieView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        mediaBucket: PropTypes.object,
        recommendationsMediaBucket: PropTypes.object,
        providers: PropTypes.object,
        match: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        mediaBucket: null,
        providers: null,
        recommendationsMediaBucket: null,
    };

    render() {
        const {mediaBucket, recommendationsMediaBucket, match, resolveMediaItemRoute, providers} = this.props;
        const {mediaItemId} = match.params;
        const mediaItem = mediaBucket && mediaItemId ? getMediaItem(mediaBucket, mediaItemId) : null;
        if (!mediaItem) return null;

        // title
        const hasHDOffers = mediaItem.offers.find(offer => offer.resolution === '1080p');
        const title = (
            <Fragment>
                {mediaItem.title}&nbsp;{hasHDOffers && (
                    <MediaItemFormatTagHD />
                )}
            </Fragment>
        );

        // provider
        const provider = typeof mediaItem.offers[0] !== 'undefined' && providers
            ? providers.get(mediaItem.offers[0].provider) : null;

        return (
            <Fragment>
                <MediaItemHeroImage mediaItem={mediaItem}>
                    <ContentRow>
                        <MediaItemDetailsMovie
                            mediaItem={mediaItem}
                            title={title}
                            provider={provider}
                            ctaButtonBar={(
                                <CTAButtonBarView mediaItem={mediaItem} />
                            )}
                            previewButton={(
                                <MediaItemPreviewButtonView mediaItem={mediaItem} />
                            )}
                        />
                    </ContentRow>
                </MediaItemHeroImage>

                {recommendationsMediaBucket && recommendationsMediaBucket.media.size > 0 ? (
                    <MediaGallery title="You might also like">
                        {recommendationsMediaBucket.media
                            .toArray()
                            .map(mediaItem => {
                                const provider = typeof mediaItem.offers[0] !== 'undefined' && providers
                                    ? providers.get(mediaItem.offers[0].provider) : null;

                                return (
                                    <MediaGalleryTilePortrait
                                        key={mediaItem.id}
                                        mediaItem={mediaItem}
                                        linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                    >
                                        <ProviderCornerRibbon provider={provider} />
                                    </MediaGalleryTilePortrait>
                                );
                            })}
                    </MediaGallery>
                ) : null}
            </Fragment>
        );
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaItemViewBucketSelector();
    const recommendationsMediaBucketSelector = selectors.createMediaItemRecommendationsViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
        recommendationsMediaBucket: recommendationsMediaBucketSelector(state, props),
        providers: state.storeConfiguration.providers,
    });
};

export default connect(
    createMapStateToProps,
)(MediaItemMovieView);
