import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import pluralize from 'pluralize';
import ContentRow from '../layout/ContentRow';
import ContentRowHeader from '../layout/ContentRowHeader';
import OriginalUIComponent from './elements/OriginalUIComponent';
import ExtendedUIComponent from './elements/ExtendedUIComponent';
import {Carousel, CarouselViewingFrame, CarouselControls, CarouselPagination, CarouselSlide} from '../ui-kit';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import * as selectors from '../media-classification-view/mediaClassificationViewSelectors';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';
import MediaCarouselSlidePortrait from '../ui-elements/media-carousel-slide/MediaCarouselSlidePortrait';
import MediaCarousel from '../ui-elements/media-carousel/MediaCarousel';

@withMediaRouteHelpers({mediaRouteResolver})
class MediaClassificationMoviesView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        mediaBucket: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        mediaBucket: null,
    };

    render() {
        const {mediaBucket, resolveMediaItemRoute} = this.props;
        const title = 'all movies';

        return (
            <div className="vub-c-style-theme-tests-view">

                {/* Carousel test */}
                <ContentRow>
                    <Carousel className="cr-is-debug-enabled">
                        <CarouselPagination />
                        <CarouselViewingFrame>
                            {[...new Array(15)].map((value, index) => (
                                <CarouselSlide key={index} className="cr-is-debug-enabled" />
                            ))}
                        </CarouselViewingFrame>
                        <CarouselControls />
                    </Carousel>
                </ContentRow>

                {mediaBucket && mediaBucket.media.size > 0 ? (
                    <ContentRow>
                        <ContentRowHeader
                            title={title}
                            subtitle={`(${mediaBucket.mediaTotalCount} ${pluralize('title', mediaBucket.mediaTotalCount)})`}
                        />
                        <MediaCarousel hasPagination>
                            {mediaBucket.media
                                .toArray()
                                .map(mediaItem => (
                                    <MediaCarouselSlidePortrait
                                        key={mediaItem.id}
                                        mediaItem={mediaItem}
                                        linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                    />
                                ))}
                        </MediaCarousel>
                    </ContentRow>
                ) : null}

                {mediaBucket && mediaBucket.isLoadingMedia ? (
                    <div className="vub-c-media-classification-movies-view__spinner">
                        <SpinnerStandard className="vub-c-standard-spinner--small" />
                    </div>
                ) : null}

                <ContentRow>
                    <OriginalUIComponent />
                    <ExtendedUIComponent />
                </ContentRow>

            </div>
        );
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaClassificationMoviesViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
    });
};

export default connect(
    createMapStateToProps,
)(MediaClassificationMoviesView);
