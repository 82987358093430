import {all, call, fork} from 'redux-saga/effects';
import routes from './routes';
import {connectedRouterSaga} from './application/connectedRouterSagas';
import {storeConfigurationWatcher} from './store-configuration/storeConfigurationSagas';
import {navigationWatcher} from './navigation/navigationSagas';
import {mediaWatcher} from './media/mediaSagas';
import {mediaEntitlementWatcher} from './media-entitlement/mediaEntitlementSagas';
import {authWatcher} from './auth/authSagas';
import {analyticsWatcher} from './analytics/analyticsSagas';

/**
 * Root redux saga
 */
const rootSaga = function* rootSaga() {
    yield all([
        fork(storeConfigurationWatcher),
        fork(navigationWatcher),
        fork(mediaWatcher),
        fork(mediaEntitlementWatcher),
        fork(authWatcher),
        fork(analyticsWatcher),

        // connected router saga should be last in sequence!
        call(connectedRouterSaga, routes),
    ]);
};

export default rootSaga;
