import resolveRoute from './resolveRoute';
import routePaths from '../routePaths';
import menuItemTypes from './menuItemTypes';

export const resolveMenuItemUrls = menus => {
    menus.forEach(menu => {
        traverse(menu);
    });
    return menus;
};

const traverse = item => {
    item.url = isMenuItemUrlResolvable(item) ? resolveMenuItemUrl(item) : null;
    if (item.children) {
        const children = item.children;
        for (let i = 0, len = children.length; i < len; i += 1) {
            traverse(children[i]);
        }
    }
};

const resolveMenuItemUrl = item => {
    switch (item.type) {
        case menuItemTypes.ALL_MOVIES:
            return resolveRoute(routePaths.MEDIA_ALL_MOVIES);
        case menuItemTypes.ALL_TV_SHOWS:
            return resolveRoute(routePaths.MEDIA_ALL_TV_SHOWS);
        case menuItemTypes.CATEGORY:
            return resolveRoute(
                routePaths.MEDIA_COLLECTION,
                {
                    mediaCollectionId: item.value,
                    classification: item.classification,
                }
            );
        case menuItemTypes.PAGE:
            return resolveRoute(routePaths.PAGE, {pageId: item.value});
        case menuItemTypes.GENRE:
            return resolveRoute(
                routePaths.MEDIA_GENRE,
                {
                    mediaGenreId: item.value,
                    classification: item.classification,
                }
            );
        default:
        // no-op
    }
};

const isMenuItemUrlResolvable = item => {
    if (item.type === menuItemTypes.ALL_MOVIES || item.type === menuItemTypes.ALL_TV_SHOWS) return true;
    if (!item.type || !item.value) return false;
    switch (item.type) {
        case menuItemTypes.PAGE:
        case menuItemTypes.CATEGORY:
        case menuItemTypes.GENRE:
            return true;
        default:
            return false;
    }
};
