import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {mediaItemClassification, mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../media-entitlement/withMediaActions';
import './CTAButtonBarView.scss';
import PurchaseCTAButtonsTVOD from './elements/PurchaseCTAButtonsTVOD';
import PurchaseCTAButtonsEST from './elements/PurchaseCTAButtonsEST';
import PurchaseCTAButtonsSVOD from './elements/PurchaseCTAButtonsSVOD';
import WatchCTAButtons from './elements/WatchCTAButtons';

@withMediaActions
class CTAButtonBarView extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {isUserSignedIn, userAccount} = this.props;
        const {mediaItem, currentEntitlements} = this.props;

        // display CTA button bar only for movies and episodes
        if (mediaItem.classification !== mediaItemClassification.MOVIE
            && mediaItem.classification !== mediaItemClassification.EPISODE) return null;

        // TODO
        // if purchased & wide: "watch" bar with more actions in pop-up
        // if not purchased & narrow: "offers" bar with actions in pop-up
        // if purchased & narrow: "watch" bar with actions in pop-up

        const entitlementOfferIds = currentEntitlements
            ? currentEntitlements.map(entitlement => entitlement.offerId) : [];
        const hasEntitlements = isUserSignedIn && !!mediaItem.offers.find(offer => {
            if (offer.offerType === mediaItemOfferType.SVOD) {
                return !!userAccount.subscriptions.find(subscription => subscription.provider === offer.provider);
            }

            if (offer.offerType === mediaItemOfferType.TVOD) {
                return entitlementOfferIds.includes(offer.id);
            }

            return false;
        });

        return (
            <div className="vub-c-cta-button-bar-view">
                {!hasEntitlements ? (
                    <Fragment>
                        <PurchaseCTAButtonsTVOD mediaItem={mediaItem} />
                        <PurchaseCTAButtonsEST mediaItem={mediaItem} />
                        <PurchaseCTAButtonsSVOD mediaItem={mediaItem} />
                    </Fragment>
                ) : (
                    <WatchCTAButtons mediaItem={mediaItem} />
                )}
            </div>
        );
    }
}

export default CTAButtonBarView;
