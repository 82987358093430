import React from 'react';
import PropTypes from 'prop-types';
import screenfull from 'screenfull';
import moment from 'moment';
import './AzurePlayerControls.scss';
import ProgressBar from './ProgressBar';
import VolumeSlider from './VolumeSlider';

const humanizeTime = time => moment.duration(Math.floor(time), 'seconds').format('h:mm:ss', {
    trim: 'left large',
    stopTrim: 'm',
});

const AzurePlayerControls = ({
    mediaItem,
    isPaused,
    volume,
    isMuted,
    isFullScreenActive,
    currentTime,
    duration,
    areControlsHidden,
    setVolume,
    toggleMute,
    togglePlayback,
    toggleFullScreen,
    setCurrentTime,
    closePlayer,
}) => (
    <div className={`vub-c-azure-player-controls ${areControlsHidden ? 'vub-c-azure-player-controls--hidden' : ''}`}>
        <div className="vub-c-azure-player-controls__top">
            <div className="vub-c-azure-player-controls__title" onClick={closePlayer}>
                <span className="vub-c-azure-player-controls__back-icon" />
                {mediaItem && mediaItem.title}
            </div>


            <div
                className={`vub-c-azure-player-controls__mute vub-c-azure-player-controls__mute--${isMuted ? 'enabled' : 'disabled'}`}
                onClick={() => {
                    if (volume !== 0) {
                        toggleMute();
                    }
                }}
            />

            <div className="vub-c-azure-player-controls__volume-slider vub-c-azure-player-controls__volume-slider--percent">
                <VolumeSlider
                    setVolume={setVolume}
                    volume={isMuted ? 0 : volume}
                />
            </div>
        </div>
        <div
            className="vub-c-azure-player-controls__playback-field"
            onClick={togglePlayback}
        />
        <div className="vub-c-azure-player-controls__bottom">

            <div className={`vub-c-azure-player-controls__playback vub-c-azure-player-controls__playback--${isPaused ? 'play' : 'pause'}`} onClick={togglePlayback} />
            <div className="vub-c-azure-player-controls__duration vub-c-azure-player-controls__duration--current-duration">
                {humanizeTime(duration)}
            </div>

            <div className="vub-c-azure-player-controls__progress-bar">
                <ProgressBar
                    setCurrentTime={setCurrentTime}
                    currentTime={currentTime}
                    duration={duration}
                />
            </div>

            <div className="vub-c-azure-player-controls__duration vub-c-azure-player-controls__duration--current-time">
                {humanizeTime(currentTime)}
            </div>

            {screenfull.enabled && (
                <div
                    className={`vub-c-azure-player-controls__full-screen vub-c-azure-player-controls__full-screen--${isFullScreenActive ? 'enabled' : 'disabled'}`}
                    onClick={toggleFullScreen}
                />
            )}
        </div>
    </div>
);


AzurePlayerControls.propTypes = {
    mediaItem: PropTypes.object,
    isPaused: PropTypes.bool,
    volume: PropTypes.node,
    isMuted: PropTypes.bool,
    isFullScreenActive: PropTypes.bool,
    currentTime: PropTypes.number,
    duration: PropTypes.number,
    areControlsHidden: PropTypes.bool,
    setVolume: PropTypes.func,
    toggleMute: PropTypes.func,
    togglePlayback: PropTypes.func,
    toggleFullScreen: PropTypes.func,
    setCurrentTime: PropTypes.func,
    closePlayer: PropTypes.func,
};

AzurePlayerControls.defaultProps = {
    mediaItem: null,
    isPaused: null,
    volume: null,
    isMuted: null,
    isFullScreenActive: null,
    currentTime: null,
    duration: null,
    areControlsHidden: null,
    setVolume: null,
    toggleMute: null,
    togglePlayback: null,
    toggleFullScreen: null,
    setCurrentTime: null,
    closePlayer: null,
};

export default AzurePlayerControls;

