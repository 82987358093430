import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../../media-entitlement/withMediaActions';
import CTAButton from '../../ui-elements/cta-button/CTAButton';
import CTAOfferInfo from '../../ui-elements/cta-offer-info/CTAOfferInfo';

@withMediaActions
class WatchCTAButtons extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {userAccount} = this.props;
        const {mediaItem, currentEntitlements, providers} = this.props;
        const {startMediaItemOfferPlayback} = this.props;

        // TODO needs refactor when subscription info source is known
        const entitlementOffers = mediaItem.offers.reduce((entitlementOffers, offer) => {
            const subscription = userAccount.subscriptions
                .find(subscription => subscription.provider === offer.provider);
            const entitlement = currentEntitlements
                .find(entitlement => entitlement.offerId === offer.id);

            if (subscription) {
                entitlementOffers[offer.id] = {
                    offer,
                    entitlement: subscription,
                };
            }

            if (entitlement) {
                entitlementOffers[offer.id] = {offer, entitlement};
            }

            return entitlementOffers;
        }, {});

        return Object.keys(entitlementOffers)
            .map(offerId => {
                const {offer, entitlement} = entitlementOffers[offerId];
                const startOfferPlayback = () => startMediaItemOfferPlayback(offer);
                const provider = providers.get(offer.provider);

                if (offer.offerType === mediaItemOfferType.TVOD || offer.offerType === mediaItemOfferType.EST) {
                    return (
                        <Fragment key={offer.id}>
                            <CTAButton onClick={startOfferPlayback}>
                                <span>
                                Watch {offer.resolution} on
                                </span>
                                {provider && (
                                <img
                                    className="vub-c-cta-button__icon vub-u-margin-left-small"
                                    src={provider.serviceCTALogo}
                                    alt="cta-icon"
                                />
                                )}
                            </CTAButton>
                            <CTAOfferInfo>
                                Playable until {entitlement.rentalExpiryTime
                                    && entitlement.rentalExpiryTime.format('DD/MM/YYYY [at] HH:mm [(GMT)]')}
                            </CTAOfferInfo>
                        </Fragment>
                    );
                }

                if (offer.offerType === mediaItemOfferType.SVOD) {
                    return (
                        <Fragment key={offer.id}>
                            <CTAButton onClick={startOfferPlayback}>
                                <span>Watch SD on</span>
                                {provider && (
                                    <img
                                        className="vub-c-cta-button__icon vub-u-margin-left-small"
                                        src={provider.serviceCTALogo}
                                        alt="cta-icon"
                                    />
                                )}
                            </CTAButton>
                            <CTAOfferInfo>
                                Included with your account
                            </CTAOfferInfo>
                        </Fragment>
                    );
                }

                return null;
            });
    }
}

export default WatchCTAButtons;
