import routePaths from './routePaths';
import * as navigationSagas from './navigation/navigationSagas';
import * as mediaSagas from './media/mediaSagas';
import * as authLoaderSagas from './auth/authLoaderSagas';
import * as storeConfigurationLoaderSagas from './store-configuration/storeConfigurationLoaderSagas';
import * as mediaEntitlementLoaderSagas from './media-entitlement/mediaEntitlementLoaderSagas';
import * as storefrontViewSagas from './storefront-view/storefrontViewSagas';
import * as mediaGenreViewSagas from './media-genre-view/mediaGenreViewSagas';
import * as mediaCollectionViewSagas from './media-collection-view/mediaCollectionViewSagas';
import * as mediaItemViewSagas from './media-item-view/mediaItemViewSagas';
import * as mediaClassificationViewSagas from './media-classification-view/mediaClassificationViewSagas';
import * as mediaSearchProviderSagas from './media/mediaSearchProviderSagas';
import * as accountLibraryViewSagas from './account-library-view/accountLibraryViewSagas';
import AppLayout from './layout/AppLayout';
import StyleGuideView from './style-guide-view/StyleGuideView';
import StaticPageView from './static-page-view/StaticPageView';
import StorefrontView from './storefront-view/StorefrontView';
import MediaCollectionView from './media-collection-view/MediaCollectionView';
import MediaGenreView from './media-genre-view/MediaGenreView';
import MediaItemView from './media-item-view/MediaItemView';
import MediaItemMovieView from './media-item-view/MediaItemMovieView';
import MediaItemTVShowView from './media-item-view/MediaItemTVShowView';
import MediaItemSeasonView from './media-item-view/MediaItemSeasonView';
import MediaItemEpisodeView from './media-item-view/MediaItemEpisodeView';
import MediaSearchView from './media-search-view/MWCMediaSearchView';
import AccountSignInView from './account-sign-in-view/AccountSignInView';
import AccountSignUpView from './account-sign-up-view/AccountSignUpView';
import AccountContactPreferencesView from './account-contact-preferences-view/AccountContactPreferencesView';
import AccountPasswordResetView from './account-password-reset-view/AccountPasswordResetView';
import AccountRequestPasswordResetView from './account-request-password-reset-view/AccountRequestPasswordResetView';
import AccountSettingsView from './account-settings-view/AccountSettingsView';
import AccountLibraryView from './account-library-view/AccountLibraryView';
import AccountOAuth2AuthenticationView from './account-oauth2-authentication-view/AccountOAuth2AuthenticationView';
import MediaClassificationMoviesView from './media-classification-view/MediaClassificationMoviesView';
import MediaClassificationTVShowsView from './media-classification-view/MediaClassificationTVShowsView';
import StyleThemeTestView from './style-theme-test-view/StyleThemeTestView';

/**
 * Location object definition (react-router, history)
 *
 * @typedef {Object} Location
 * @property {string} key - key is available for all by HashHistory
 * @property {string} pathname - path part of route
 * @property {string} search - search query part of route
 * @property {string} hash - hash (#) part of route
 * @property {Object} state - user defined state for the route
 */

/**
 * ConnectedRoute object definition (react-router-config, connected-react-router, redux-saga)
 *
 * @typedef {Object} ConnectedRoute
 * @property {string} path - any valid URL path that path-to-regexp understands.
 * @property {?Object} component - React component
 * @property {?function} render - render prop function
 * @property {?Location} location - for matching against different location than one in history
 * @property {?boolean} exact - when true, will only match if the path matches the location.pathname exactly
 * @property {?boolean} string - when true, a path that has a trailing slash will only match a location.pathname
 *                      with a trailing slash
 * @property {?boolean} sensitive - when true, will match if the path is case sensitive.
 * @property {?Array<ConnectedRoute>} routes - sub-routes
 * @property {?Array<Array<[saga, Object]>>} locationChangeSideEffects - Redux sagas (side-effects) to be run
 *                                            with payload object
 */

/**
 * Static route configuration
 *
 * @type {Array<ConnectedRoute>}
 */
export default [{
    component: AppLayout,
    locationChangeSideEffects: [
        [navigationSagas.fetchNavigation],
        [mediaSagas.loadMediaGenres],
        [authLoaderSagas.loadUserSession],
        [storeConfigurationLoaderSagas.loadProviders],
        [mediaEntitlementLoaderSagas.loadCurrentEntitlements],
    ],
    routes: [
        {
            path: routePaths.STOREFRONT,
            exact: true,
            component: StorefrontView,
            locationChangeSideEffects: [
                [storefrontViewSagas.loadStorefrontViewContent],
            ],
        },
        {
            path: routePaths.MEDIA_COLLECTION,
            component: MediaCollectionView,
            locationChangeSideEffects: [
                [mediaCollectionViewSagas.loadMediaForMediaCollectionView],
            ],
        },
        {
            path: routePaths.MEDIA_GENRE,
            component: MediaGenreView,
            locationChangeSideEffects: [
                [mediaGenreViewSagas.loadMediaForMediaGenreView],
            ],
        },
        {
            path: routePaths.MEDIA_ITEM,
            component: MediaItemView,
            locationChangeSideEffects: [
                [mediaItemViewSagas.loadMediaForMediaItemView],
            ],
        },
        {
            path: routePaths.MEDIA_ITEM_MOVIE,
            component: MediaItemMovieView,
            locationChangeSideEffects: [
                [mediaItemViewSagas.loadMediaForMediaItemView],
                [mediaItemViewSagas.loadMediaForMediaItemRecommendationsView],
            ],
        },
        {
            path: routePaths.MEDIA_ITEM_EPISODE,
            component: MediaItemEpisodeView,
            locationChangeSideEffects: [
                [mediaItemViewSagas.loadMediaForMediaItemView],
            ],
        },
        {
            path: routePaths.MEDIA_ITEM_SEASON,
            component: MediaItemSeasonView,
            locationChangeSideEffects: [
                [mediaItemViewSagas.loadMediaForMediaItemView],
            ],
        },
        {
            path: routePaths.MEDIA_ITEM_TV_SHOW,
            component: MediaItemTVShowView,
            locationChangeSideEffects: [
                [mediaItemViewSagas.loadMediaForMediaItemView],
                [mediaItemViewSagas.loadMediaForMediaItemRecommendationsView],
            ],
        },
        {
            path: routePaths.MEDIA_ALL_MOVIES,
            component: MediaClassificationMoviesView,
            locationChangeSideEffects: [
                [mediaClassificationViewSagas.loadMediaForMediaClassificationView, {classification: 'movies'}],
            ],
        },
        {
            path: routePaths.MEDIA_ALL_TV_SHOWS,
            component: MediaClassificationTVShowsView,
            locationChangeSideEffects: [
                [mediaClassificationViewSagas.loadMediaForMediaClassificationView, {classification: 'tv-shows'}],
            ],
        },
        {
            path: routePaths.MEDIA_SEARCH,
            component: MediaSearchView,
            locationChangeSideEffects: [
                [mediaSearchProviderSagas.loadMediaForMediaSearchView],
            ],
        },
        {
            path: routePaths.PAGE,
            component: StaticPageView,
        },
        {
            path: routePaths.SIGN_IN,
            component: AccountSignInView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {roles: ['ANONYMOUS'], redirectTo: routePaths.ACCOUNT_SETTINGS}],
                [authLoaderSagas.loadAuthFormDefaultState],
            ],
        },
        {
            path: routePaths.SIGN_UP,
            component: AccountSignUpView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {roles: ['ANONYMOUS'], redirectTo: routePaths.ACCOUNT_SETTINGS}],
                [authLoaderSagas.loadAuthFormDefaultState],
            ],
        },
        {
            path: routePaths.SET_CONTACT_PREFERENCES,
            component: AccountContactPreferencesView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {roles: ['PRIVATE'], redirectTo: routePaths.SIGN_IN}],
                [authLoaderSagas.loadAuthFormDefaultState],
            ],
        },
        {
            path: routePaths.ACCOUNT_SETTINGS,
            component: AccountSettingsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {roles: ['PRIVATE'], redirectTo: routePaths.SIGN_IN}],
                [authLoaderSagas.loadAuthFormDefaultState],
            ],
        },
        {
            path: routePaths.ACCOUNT_LIBRARY,
            component: AccountLibraryView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {roles: ['PRIVATE'], redirectTo: routePaths.SIGN_IN}],
                [accountLibraryViewSagas.loadMediaForAccountLibraryView],
            ],
        },
        {
            path: routePaths.PASSWORD_RECOVERY,
            component: AccountRequestPasswordResetView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {roles: ['ANONYMOUS'], redirectTo: routePaths.ACCOUNT_SETTINGS}],
                [authLoaderSagas.loadAuthFormDefaultState],
            ],
        },
        {
            path: routePaths.PASSWORD_RESET,
            component: AccountPasswordResetView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {roles: ['ANONYMOUS'], redirectTo: routePaths.ACCOUNT_SETTINGS}],
                [authLoaderSagas.loadAuthFormDefaultState],
            ],
        },
        {
            path: routePaths.OAUTH2_AUTHENTICATION,
            component: AccountOAuth2AuthenticationView,
            locationChangeSideEffects: [
                [authLoaderSagas.loadOAuth2AccessToken],
            ],
        },
        {
            path: routePaths.OIDC_AUTHENTICATION,
            component: AccountOAuth2AuthenticationView,
            locationChangeSideEffects: [
                [authLoaderSagas.loadOIDCAccessToken],
            ],
        },
        {
            path: routePaths.STYLE_GUIDE,
            component: StyleGuideView,
        },
        {
            path: '/style-theme-test',
            component: StyleThemeTestView,
            locationChangeSideEffects: [
                [mediaClassificationViewSagas.loadMediaForMediaClassificationView, {classification: 'movies'}],
            ],
        },
        {
            path: '*',
            component: StaticPageView,
        },
    ],
}];
