import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemDetailsMovie.scss';
import MediaItemDetailsTitle from './elements/MediaItemDetailsTitle';
import MediaItemDetailsBoxCover from './elements/MediaItemDetailsBoxCover';
import MediaItemDetailsPrimaryInfo from './elements/MediaItemDetailsPrimaryInfo';
import MediaItemDetailsContentInfo from './elements/MediaItemDetailsContentInfo';
import MediaItemDetailsSynopsis from './elements/MediaItemDetailsSynopsis';
import MediaItemDetailsCredits from './elements/MediaItemDetailsCredits';
import ProviderCornerRibbon from '../provider-corner-ribbon/ProviderCornerRibbon';

const MediaItemDetailsMovie = props => {
    const {mediaItem, title, subtitle, provider} = props;
    const {ctaButtonBar, previewButton} = props;

    return (
        <div className="vub-c-media-item-details-movie">
            <div className="vub-c-media-item-details-movie__box-cover">
                <MediaItemDetailsBoxCover mediaItem={mediaItem}>
                    <ProviderCornerRibbon provider={provider} />
                </MediaItemDetailsBoxCover>
            </div>
            <div className="vub-c-media-item-details-movie__title">
                <MediaItemDetailsTitle title={title} subtitle={subtitle} />
            </div>
            <div className="vub-c-media-item-details-movie__main">
                <MediaItemDetailsPrimaryInfo mediaItem={mediaItem} />
                {ctaButtonBar}
            </div>
            <div className="vub-c-media-item-details-movie__preview-button">
                {previewButton}
            </div>
            <div className="vub-c-media-item-details-movie__content-info">
                <MediaItemDetailsContentInfo mediaItem={mediaItem} />
            </div>
            <div className="vub-c-media-item-details-movie__synopsis">
                <MediaItemDetailsSynopsis mediaItem={mediaItem} />
            </div>
            <div className="vub-c-media-item-details-movie__credits">
                <MediaItemDetailsCredits mediaItem={mediaItem} />
            </div>
        </div>
    );
};

MediaItemDetailsMovie.propTypes = {
    mediaItem: PropTypes.object.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    provider: PropTypes.object,
    ctaButtonBar: PropTypes.node,
    previewButton: PropTypes.node,
};

MediaItemDetailsMovie.defaultProps = {
    title: null,
    subtitle: null,
    provider: null,
    ctaButtonBar: null,
    previewButton: null,
};

export default MediaItemDetailsMovie;
