import {Record} from 'immutable';
import moment from 'moment';
import Offer from './Offer';
import createFakeOffers from './createFakeOffers';

/**
 * MediaItem content advisory rating mappings
 * TODO move to language vars
 */
const contentAdvisoryRatingMap = {
    'NR': 'This film has not yet been rated.',
    '12': 'Suitable for 12 years and over.',
    '15': 'Suitable only for 15 years and over.',
    '18': 'Suitable only for adults.',
    'PG': 'Parental Guidance recommended.',
    'U': 'Suitable for all.',
};

/**
 * Resolve content advisory rating
 *
 * @param rating {string}
 * @param ratingMapConstant {object}
 * @param defaultRatingValue {string}
 *
 * @returns {string}
 * @private
 */
const mapContentAdvisoryRating = (rating, ratingMapConstant, defaultRatingValue = '') => (
    ratingMapConstant[rating] || defaultRatingValue
);

/**
 * Returns array of Offers
 */
const parseOffersFromDTO = dataTransferObject => {
    const offers = [];
    dataTransferObject.offers.forEach(offerDTO => {
        const offer = new Offer({}).fromDTO(offerDTO);
        if (offer.id) {
            offers.push(offer);
        }
    });

    // TODO remove when not needed, add config value
    const fakeOfferDTOs = createFakeOffers(dataTransferObject.id, dataTransferObject.offers);
    fakeOfferDTOs.forEach(offerDTO => {
        const offer = new Offer({}).fromDTO(offerDTO);
        if (offer.id) {
            offers.push(offer);
        }
    });

    return offers;
};

/**
 * MediaItem definition
 *
 * @typedef {Immutable.Record} MediaItem
 * @property {?string} id
 * @property {?Array<Object>} alternateIds
 * @property {?string} classification
 * @property {?string} title
 * @property {?string} titleShort
 * @property {?string} titleMedium
 * @property {?string} titleLong
 * @property {?string} titleSort
 * @property {?string} synopsis
 * @property {?string} synopsisShort
 * @property {?string} synopsisMedium
 * @property {?string} synopsisLong
 * @property {?Array<string>} language
 * @property {?Array<string>} audioLanguage
 * @property {?string} year
 * @property {?string} duration
 * @property {?string} durationMinutes
 * @property {?string} mediaGenreId
 * @property {?Array<string>} categories
 * @property {?Array<Object>} staffMembers
 * @property {?string} contentAdvisoryRatingSystem
 * @property {?string} contentAdvisoryRating
 * @property {?string} parentalAdvisory
 * @property {?string} imageBoxCover
 * @property {?string} imageThumbnail
 * @property {?string} imagePoster
 * @property {?number} childrenCount
 * @property {?number} siblingIndex
 * @property {?string} parentId
 * @property {?Array<string>} childrenIds
 * @property {?Object} preview
 * @property {Array<Offer>} offers
 */

/**
 * MediaItem record
 *
 * @type {MediaItem}
 */
class MediaItem extends Record({
    id: null,
    alternateIds: [],
    classification: null,
    title: null,
    titleShort: null,
    titleMedium: null,
    titleLong: null,
    titleSort: null,
    synopsis: null,
    synopsisShort: null,
    synopsisMedium: null,
    synopsisLong: null,
    language: null,
    year: null,
    duration: null,
    durationMinutes: null,
    mediaGenreId: null,
    categories: null,
    staffMembers: [],
    contentAdvisoryRatingSystem: 'NR',
    contentAdvisoryRating: null,
    parentalAdvisory: null,
    imageBoxCover: null,
    imageThumbnail: null,
    imagePoster: null,
    childrenCount: 0,
    siblingIndex: null,
    parentId: null,
    childrenIds: [],
    preview: null,
    offers: [],
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {MediaItem}
     */
    fromDTO(dataTransferObject) {
        const durationMoment = typeof dataTransferObject.duration !== 'undefined'
            ? moment.duration(dataTransferObject.duration) : null;

        return new MediaItem({
            ...this.toObject(),
            ...dataTransferObject,
            durationMinutes: durationMoment && (durationMoment.asMinutes() > 0)
                ? durationMoment.format('m [min]') : this.durationMinutes,
            parentalAdvisory: dataTransferObject.contentAdvisoryRating
                ? mapContentAdvisoryRating(dataTransferObject.contentAdvisoryRating, contentAdvisoryRatingMap)
                : this.parentalAdvisory,
            offers: dataTransferObject.offers ? parseOffersFromDTO(dataTransferObject) : this.offers,
        });
    }
}

export default MediaItem;
