import store from 'store';
import ua from 'universal-analytics';
import uuidGenerator from 'uuid';
import AuthService from '@comrock/vub-wls-auth-service';
// import AuthProviderSMP from '@comrock/vub-wls-auth-provider-smp';
import AuthProviderOIDC from '@comrock/vub-wls-auth-provider-oidc';
import AuthProviderFilmFlex from '@comrock/vub-wls-auth-provider-film-flex';
import WLSConfigurationService from '@comrock/vub-wls-configuration-service';
import MediaService from '@comrock/vub-wls-media-service';
// import MediaProviderSMPInMemory from '@comrock/vub-wls-media-provider-smp-in-memory';
import MediaProviderSMPStateful from '@comrock/vub-wls-media-provider-smp-stateful';
import MediaEntitlementService from '@comrock/vub-wls-media-entitlement-service';
// import MediaEntitlementProviderSMPInMemory from '@comrock/vub-wls-media-entitlement-provider-smp-in-memory';
import MediaEntitlementProviderSMPTransactional from '@comrock/vub-wls-media-entitlement-provider-smp-transactional';
import MediaEntitlementProviderFilmFlex from '@comrock/vub-wls-media-entitlement-provider-film-flex';
import CustomMediaRouteResolver from './navigation/CustomMediaRouteResolver';
import config from './config';

export const wlsConfigurationService = new WLSConfigurationService({
    serviceURL: config.WLS_CONFIGURATION_API_URI,
});

// const mediaProviderSMPInMemory = new MediaProviderSMPInMemory({
//     smpCatalogueAPI: {
//         serviceURL: config.SMP_CATALOGUE_API_URI,
//         providerId: config.SMP_CATALOGUE_API_PROVIDER_ID,
//     },
//     smpProductAPI: {
//         serviceURL: config.SMP_PRODUCT_API_URI,
//     },
//     smpSearchAPI: {
//         serviceURL: config.SMP_SEARCH_API_URI,
//         apiKey: config.SMP_SEARCH_API_KEY,
//         apiVersion: config.SMP_SEARCH_API_VERSION,
//     },
// });

const mediaProviderSMPStateful = new MediaProviderSMPStateful({
    smpCatalogueAPI: {
        serviceURL: config.SMP_CATALOGUE_API_URI,
        providerId: config.SMP_CATALOGUE_API_PROVIDER_ID,
    },
    smpProductAPI: {
        serviceURL: config.SMP_PRODUCT_API_URI,
    },
    smpSearchAPI: {
        serviceURL: config.SMP_SEARCH_API_URI,
        apiKey: config.SMP_SEARCH_API_KEY,
        apiVersion: config.SMP_SEARCH_API_VERSION,
    },
});

// export const mediaService = new MediaService(mediaProviderSMPInMemory);
export const mediaService = new MediaService(mediaProviderSMPStateful);

// TODO auth provider branching should be done in affiliate package
let authProvider;
let mediaEntitlementProvider;

if (!config.TEMP_USE_SSO_AUTH_PROVIDER) {
    authProvider = new AuthProviderFilmFlex({
        serviceURL: config.FF_API_URI,
        apiVersion: config.FF_API_VERSION,
        trustedApplicationToken: config.FF_API_TRUSTED_APPLICATION_TOKEN,
        affiliateId: config.FF_API_AFFILIATE_ID,
    });

    mediaEntitlementProvider = new MediaEntitlementProviderFilmFlex({
        ccIdentityAPI: {
            serviceURL: config.CC_IDENTITY_API_URI,
            clientId: config.CC_IDENTITY_API_CLIENT_ID,
            clientSecret: config.CC_IDENTITY_API_CLIENT_SECRET,
        },
        ccAPI: {
            serviceURL: config.CC_API_URI,
            providerId: config.CC_API_PROVIDER_ID,
            provider: config.CC_API_PROVIDER,
        },
        ffAPI: {
            serviceURL: config.FF_API_URI,
            apiVersion: config.FF_API_VERSION,
            trustedApplicationToken: config.FF_API_TRUSTED_APPLICATION_TOKEN,
            affiliateId: config.FF_API_AFFILIATE_ID,
        },
    });
}

if (config.TEMP_USE_SSO_AUTH_PROVIDER) {
    authProvider = new AuthProviderOIDC({
        serviceURL: config.OPEN_ID_API_URI,
        clientId: config.OPEN_ID_API_CLIENT_ID,
        clientSecret: config.OPEN_ID_API_CLIENT_SECRET,
    });

    mediaEntitlementProvider = new MediaEntitlementProviderSMPTransactional({
        smpAccountAPI: {
            serviceURL: config.SMP_ACCOUNT_API_URI,
        },
        smpTransactionAPI: {
            serviceURL: config.SMP_TRANSACTION_API_URI,
            providerId: config.SMP_CATALOGUE_API_PROVIDER_ID,
        },
        smpSearchAPI: {
            serviceURL: config.SMP_SEARCH_API_URI,
        },
        smpCatalogueAPI: {
            serviceURL: config.SMP_CATALOGUE_API_URI,
            providerId: config.SMP_CATALOGUE_API_PROVIDER_ID,
        },
        smpProductAPI: {
            serviceURL: config.SMP_PRODUCT_API_URI,
        },
        smpPlayerEntitlementAPI: {
            serviceURL: config.SMP_PLAYER_ENTITLEMENT_API_URI,
        },
    });

    // authProvider = new AuthProviderSMP({
    //     ccIdentityAPI: {
    //         serviceURL: config.CC_IDENTITY_API_URI,
    //         clientId: config.CC_IDENTITY_API_CLIENT_ID,
    //         clientSecret: config.CC_IDENTITY_API_CLIENT_SECRET,
    //     },
    //     uxpIdentityService: {
    //         identityServiceURL: config.UXP_IDENTITY_URI,
    //         accountServiceURL: config.UXP_ACCOUNT_URI,
    //     },
    //     smpAccountAPI: {
    //         serviceURL: config.SMP_ACCOUNT_API_URI,
    //     },
    // });

    // mediaEntitlementProvider = new MediaEntitlementProviderSMPInMemory({
    //     mediaProviderSMPInMemory: {
    //         mediaIndexPromise: Promise.resolve([]),
    //     },
    //     // mediaProviderSMPInMemory,
    //     ccIdentityAPI: {
    //         serviceURL: config.CC_IDENTITY_API_URI,
    //         clientId: config.CC_IDENTITY_API_CLIENT_ID,
    //         clientSecret: config.CC_IDENTITY_API_CLIENT_SECRET,
    //     },
    //     ccAPI: {
    //         serviceURL: config.CC_API_URI,
    //         providerId: config.CC_API_PROVIDER_ID,
    //         provider: config.CC_API_PROVIDER,
    //     },
    //     smpCatalogueAPI: {
    //         serviceURL: config.SMP_CATALOGUE_API_URI,
    //     },
    // });
}

export const authService = new AuthService(authProvider);

export const mediaEntitlementService = new MediaEntitlementService(mediaEntitlementProvider);

const gaCID = store.get('ga_cid') || uuidGenerator.v4();
store.set('ga_cid', gaCID);
export const gaService = ua(config.GA_TRACKING_ID, gaCID, {https: true});

export const mediaRouteResolver = new CustomMediaRouteResolver();
