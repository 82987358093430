import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemDetailsEpisode.scss';
import MediaItemDetailsTitle from './elements/MediaItemDetailsTitle';
import MediaItemDetailsBoxCover from './elements/MediaItemDetailsBoxCover';
import MediaItemDetailsPrimaryInfo from './elements/MediaItemDetailsPrimaryInfo';
import MediaItemDetailsContentInfo from './elements/MediaItemDetailsContentInfo';
import MediaItemDetailsSynopsis from './elements/MediaItemDetailsSynopsis';
import MediaItemDetailsCredits from './elements/MediaItemDetailsCredits';
import CustomSelect from '../custom-select/CustomSelect';
import Option from '../custom-select/Option';
import ProviderCornerRibbon from '../provider-corner-ribbon/ProviderCornerRibbon';

const MediaItemDetailsEpisode = props => {
    const {mediaItem, title, subtitle, provider} = props;
    const {ctaButtonBar} = props;
    const {seasonMediaItem, seasonSelectOptions, onSeasonSelect} = props;

    return (
        <div className="vub-c-media-item-details-episode">
            <div className="vub-c-media-item-details-episode__box-cover">
                <MediaItemDetailsBoxCover mediaItem={seasonMediaItem}>
                    <ProviderCornerRibbon provider={provider} />
                </MediaItemDetailsBoxCover>
            </div>
            <div className="vub-c-media-item-details-episode__title">
                <MediaItemDetailsTitle title={title} subtitle={subtitle} />
            </div>
            <div className="vub-c-media-item-details-episode__main">
                <MediaItemDetailsPrimaryInfo mediaItem={mediaItem} />
                {ctaButtonBar}
            </div>
            <div className="vub-c-media-item-details-episode__season-select">
                <CustomSelect
                    value={seasonMediaItem.id}
                    onChange={onSeasonSelect}
                >
                    {seasonSelectOptions.map(option => (
                        <Option
                            key={option.id}
                            value={option.value}
                        >
                            {option.name}
                        </Option>
                    ))}
                </CustomSelect>
            </div>
            <div className="vub-c-media-item-details-episode__content-info">
                <MediaItemDetailsContentInfo mediaItem={mediaItem} />
            </div>
            <div className="vub-c-media-item-details-episode__synopsis">
                <MediaItemDetailsSynopsis mediaItem={mediaItem} />
            </div>
            <div className="vub-c-media-item-details-episode__credits">
                <MediaItemDetailsCredits mediaItem={mediaItem} />
            </div>
        </div>
    );
};

MediaItemDetailsEpisode.propTypes = {
    mediaItem: PropTypes.object.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    provider: PropTypes.object,
    ctaButtonBar: PropTypes.node,
    seasonMediaItem: PropTypes.object.isRequired,
    onSeasonSelect: PropTypes.func,
    seasonSelectOptions: PropTypes.array,
};

MediaItemDetailsEpisode.defaultProps = {
    title: null,
    subtitle: null,
    provider: null,
    ctaButtonBar: null,
    onSeasonSelect: null,
    seasonSelectOptions: [],
};

export default MediaItemDetailsEpisode;
