import Immutable from 'immutable';

/**
 * MediaItemPlaybackSource definition
 *
 * @typedef {Immutable.Record} MediaItemPlaybackSource
 * @property {?string} url
 * @property {?string} type
 * @property {?Object} protectionInfo
 */

/**
 * MediaItemPlaybackSource record
 *
 * @type {MediaItemPlaybackSource}
 */
class MediaItemPlaybackSource extends Immutable.Record({
    url: null,
    type: null,
    protectionInfo: null,
}) {}

export default MediaItemPlaybackSource;
